import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  flex: {
    display: 'flex',
    '& a': {
      margin: 'auto',
    },
    [breakpoints.down('md')]: {
      display: 'block',
      '& a': {
        textAlign: 'center',
        margin: spacing(4, 'auto'),
      },
    },
  },
}))

export default useStyles
